.icon {
    width: 1.25em;
    height: 1.25em;
    fill: currentColor;
    vertical-align: middle;
    flex-shrink: 0;

    &--black {
        color: $color-black;
    }
    &--light {
        color: $color-secondary-darker;
    }
    &--action {
        color: $color-action;
    }

    &--end {
        margin: 0 0 0 auto;
    }

    &--small {
        width: 1rem;
        height: 1rem;
    }

    &--large {
        width: 1.75rem;
        height: 1.75rem;
    }

    &.space-left {
        margin-left: math.div($space-gap, 2);
    }

    &.space-right {
        margin-right: math.div($space-gap, 2);
    }

    &.share-icon {
        width: 1.25rem;
        height: 1.25rem;
        &:hover {
            color: $color-primary;
        }
    }

    &.share-icon-red {
        width: 1.25rem;
        height: 1.25rem;
        color: $color-primary;
        &:hover {
            color: $color-primary;
        }
    }
}

;@import "sass-embedded-legacy-load-done:28";