.column {
    display: flex;
    flex-flow: column wrap;
    position: relative;
    gap: 1rem;

    .block {
        margin: 0;
    }
    > p,
    > h2,
    > h3,
    > h4,
    > h5 {
        margin: 0;
    }
    .cta-block {
        height: 100%;

        &__background-image {
            display: none;
        }
        &__body {
            padding: 2.5rem;
        }
    }
}
.container {
    &.width-full {
        .block-two_column {
            .cta-block {
                &__background-image {
                    display: block;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:41";