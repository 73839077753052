summary {
    padding: 1.625rem 0;
    color: $color-tertiary;
    transition: all 1s ease;
    flex-wrap: nowrap !important;
    gap: 1rem;
    font-size: 1rem;
    line-height: 23px;
    .icon {
        width: 16px;
        height: 12px;
    }

    span {
        padding: 0;
    }
    .open {
        display: flex;
        align-items: center;
    }
    .closed {
        display: none;
    }

    // Hide the triangle marker that shows in some browsers
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }
}
details {
    border-bottom: 1px solid $color-tertiary;
    color: #4b5152;
    &:not(:first-of-type) {
        margin-top: 0 !important;
    }

    &[open] {
        .open {
            display: none;
        }
        .closed {
            display: flex;
            align-items: center;
        }
    }
    .rich-text {
        padding: 0 0 1rem;
    }
    summary {
        padding-bottom: 0.25rem;
    }
}

;@import "sass-embedded-legacy-load-done:43";