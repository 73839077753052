.content-list {
    padding: 1rem 0 0 0;
    position: relative;
    ul {
        padding-left: 0;
    }
    li:before {
        content: none;
    }
    @include breakpoint(small) {
        .content-list__item {
            display: block;
        }
    }
    label {
        position: absolute;
        top: -6rem;
        right: 2.5rem;
        color: $color-secondary-darker;
    }
}
.content-list__buttons {
    .button {
        margin: 1rem 0;
    }
}

;@import "sass-embedded-legacy-load-done:52";