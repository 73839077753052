// Colors

$color-primary: #e4003a !default;
$color-primary-lighter: lighten($color-primary, 10%) !default;
$color-primary-darker: darken($color-primary, 10%) !default;
$color-secondary: #20bec6 !default;
$color-secondary-lighter: lighten($color-secondary, 10%) !default;
$color-secondary-darker: darken($color-secondary, 10%) !default;
$color-tertiary: #09232f !default;
$color-tertiary-lighter: lighten($color-tertiary, 10%) !default;
$color-tertiary-darker: darken($color-tertiary, 10%) !default;
$color-action: #fbba2c !default;
$color-action-lighter: lighten($color-action, 10%) !default;
$color-action-darker: darken($color-action, 10%) !default;
$color-beige: #e3dfd2;
$color-beige-lighter: #e9e5db;
$color-beige-lightest: #f2f0ea;
$color-beige-darker: #cfcbbf;

$color-black: #1e2527 !default;
$color-white: #ffffff !default;
$color-grey: #616972 !default;
$color-medium-grey: #a8b3be !default;
$color-light-grey: #d3dbe3 !default;
$color-lightest-grey: #ebeef2 !default;

$color-insert: #258750 !default;
$color-delete: $color-action !default;
$color-footer: #09232f !default;
$color-background: #09232f !default;
$color-line: #d3dbe3 !default;
$color-label: $color-grey !default;

;@import "sass-embedded-legacy-load-done:7";