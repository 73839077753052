footer {
    background: $color-tertiary;
    color: $color-white;
    gap: 2rem;
    padding: 2rem 1.5rem 5rem;
    transition: all 0.5s ease;
    width: 100vw;
    padding-left: 0;
    padding-right: 0;

    nav {
        h4 {
            display: none;
        }
    }

    @include breakpoint(medium) {
        gap: 4.5rem;
        padding: 4.5rem 5rem 3rem;
    }

    a {
        color: $color-white;
    }
    .block-socials {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        li {
            color: $color-white;
        }
    }
    .footer-newsletter {
        margin: 0 1rem 2rem 0;
        #newsletter-signup {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: column;
            @include breakpoint(large) {
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                > div {
                    width: 100%;
                    input[type='text'] {
                        border-radius: 50rem 0 0 50rem;
                        border: 1px solid $color-white;
                        border-right: none;
                        height: calc(3rem + 2px);
                    }
                }
            }
            .button {
                padding: 0 2.5rem;
            }
            input::placeholder {
                color: $color-white;
            }
            .field > label {
                color: $color-white;
            }
        }

        @include breakpoint(enormous) {
            margin: 0;
        }

        .form-heading {
            font-weight: 400;
            font-size: $body-text-xxl;
            color: $color-white;
            margin: 0;
        }
        .newsletter-form-wrapper {
            color: $color-white;
            margin-top: 1rem;

            @include breakpoint(large) {
                height: 48px;
                .submit-container {
                    border-radius: 0 50rem 50rem 0 !important ;
                    border: 1px solid $color-white !important;
                    border-left: none !important;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            input {
                background-color: transparent;
                color: $color-white;
                outline: none;
                line-height: 3rem;
                border-radius: 50rem;
                border: 1px solid $color-white;
                padding-left: 1.5rem;
                margin-bottom: 0.5rem;
                min-width: 100%;
                height: 3rem;
                ::placeholder {
                    color: $color-white !important;
                }
                @include breakpoint(medium) {
                    min-width: 20vw;
                }
                @include breakpoint(large) {
                    margin-bottom: 0;
                    border: 0;
                }
            }
            input[type='submit'] {
                width: 100%;
            }

            .button {
                background: rgba($color-white, 0.2);
                border-radius: 50rem;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 1rem;
                padding: 1rem 2.5rem;
                min-width: 100%;
                color: $color-white;
                margin: 0;
                border: none;

                @include breakpoint(medium) {
                    min-width: unset;
                }
            }
        }
    }
    .footer-social {
        font-size: 0.875rem;
        line-height: 1.181rem;
        font-family: $base-font;
        font-family: 400;
        @include breakpoint(large) {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        .social {
            margin-top: 1rem;
            gap: 1rem;

            .button--social {
                margin: 0 !important;
                padding: 0 !important;
                min-height: unset;

                .icon {
                    fill: #ffffff;
                    width: 1.5rem;
                    height: 1.5rem;
                    margin: 0 !important;
                }
            }
        }
    }
    .footer-blocks {
        min-width: 100%;
        margin-bottom: 2.5rem;

        @include breakpoint(medium) {
            min-width: unset;
            margin-bottom: 0;
        }

        nav {
            ul {
                list-style: none;
                padding: 0;

                li {
                    &:before {
                        content: '';
                    }
                    a {
                        font-size: 0.875rem;
                        line-height: 1.181rem;
                        font-family: $base-font;
                        font-family: 400;
                    }
                }
            }
        }
        .block {
            margin: 0;
        }
        .column {
            @include breakpoint(medium) {
                min-width: 13.5rem;
            }
        }
    }
}
.footer-logo {
    margin: 0;

    &__image {
        display: block;
        max-width: 13rem;
        max-height: 7.5rem;
        width: auto;
        height: auto;
    }
}

.footer__navigation .block-menu {
    display: flex;
    flex-direction: column;
    margin: 0;

    h5 {
        a {
            color: $color-black;
            font-size: 1.2rem;
            margin: 0 1rem;
        }
    }

    .footer-quicklinks__menu {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;

        a {
            margin: 0 auto 0.3rem 0;
            padding: 0.5rem 1rem;
        }
        a.button {
            margin: 0 auto 0.3rem 2.2rem;
        }

        .button_link {
            padding: 0.3125rem 0.75rem;
            border-radius: 0.25rem;
            font-size: 0.9375rem;
        }
    }
}

.footer-legal {
    border-top: 1px solid $color-white;
    padding-top: 1rem;
    gap: 1rem;
    width: 100%;

    .rich-text,
    p {
        max-width: 100%;
        margin: 0;
        font-family: $base-font;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: left;
    }
    .built-by-dev {
        margin: 0;
        a {
            text-decoration: none;
        }
    }
}

.footer-container {
    margin: auto;
    gap: 2rem;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1.9rem;
}

.footer__bottom {
    img {
        object-fit: contain;
    }
}

.footer__top {
    position: relative;

    .container {
        gap: 2.5rem;
    }

    .top-link {
        background: transparent;
        border: 1px solid rgba($color-white, 0.2);
        margin: 0;

        .icon {
            fill: $color-white;
        }
    }
}

.footer {
    .cta-block__background-image {
        width: 100% !important;

        img {
            width: 100% !important;
        }
    }
}
html[lang='it'],
html[lang='fr'] {
    .footer {
        &-newsletter {
            .button {
                font-size: clamp(0.7rem, 3.5vw, 1rem);
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:17";