// Base sizes
$base-font-size: 1rem !default;
$base-font:
    'proxima-nova',
    -apple-system,
    'Segoe UI',
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    'Helvetica Neue',
    sans-serif;
$base-line-height: 1.4;

//Font Sizes
$heading-xxl: 5.5rem;
$heading-xl: 4rem;
$heading-l: 3rem;
$heading-m: 2rem;
$heading-s: 1.75rem;
$heading-xs: 1rem;
$heading-xxs: 0.875rem;

$body-text-xxl: 1.5rem;
$body-text-xl: 1.25rem;
$body-text-l: 1.125rem;
$body-text-m: 1rem;
$body-text-s: 0.875rem;
$body-text-xs: 0.75rem;
$body-text-xxs: 0.625rem;

// Harmony ratio
$harmony-ratio: 1.33 !default;

// Size
$width-full: 100vw !default;
$width-text: 35.1875rem !default; // 618px / 6cols
$width-text-wide: 59rem !default;
$width-block: 52rem !default; // 832px / 8cols
$width-block-wide: 90rem !default;
$width-container: 78.75rem !default; // 1260px / 12cols
$width-border: 2px !default;
$width-sidebar: 18.75rem !default;
$width-logo: 4.06rem !default;

// Spacing
$space-gap: 1rem;

// Radius
$border-radius: math.div($space-gap, 4);

// Responsive breakpoints
$breakpoint-small: 460px;
$breakpoint-medium: 640px;
$breakpoint-large: 960px;
$breakpoint-enormous: 1440px;
$breakpoint-ginormous: 2560px;

;@import "sass-embedded-legacy-load-done:6";