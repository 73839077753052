.separator-block {
    &.margin-top {
        &-6 {
            margin-top: $space-gap * 3.25;

            @include breakpoint(medium) {
                margin-top: $space-gap * 7.5;
            }
        }
        &-5 {
            margin-top: $space-gap * 2.5;

            @include breakpoint(medium) {
                margin-top: $space-gap * 5;
            }
        }
        &-4 {
            margin-top: $space-gap * 2;

            @include breakpoint(medium) {
                margin-top: $space-gap * 4;
            }
        }
        &-3 {
            margin-top: $space-gap * 1.75;

            @include breakpoint(medium) {
                margin-top: $space-gap * 2.5;
            }
        }
        &-2 {
            margin-top: $space-gap * 1.5;

            @include breakpoint(medium) {
                margin-top: $space-gap * 1.5;
            }
        }
        &-1 {
            margin-top: $space-gap;

            @include breakpoint(medium) {
                margin-top: $space-gap;
            }
        }
        &-none {
            margin-top: 0;
        }
    }

    &.margin-bottom {
        &-6 {
            margin-bottom: $space-gap * 3.25;

            @include breakpoint(medium) {
                margin-bottom: $space-gap * 7.5;
            }
        }
        &-5 {
            margin-bottom: $space-gap * 2.5;

            @include breakpoint(medium) {
                margin-bottom: $space-gap * 5;
            }
        }
        &-4 {
            margin-bottom: $space-gap * 2;

            @include breakpoint(medium) {
                margin-bottom: $space-gap * 4;
            }
        }
        &-3 {
            margin-bottom: $space-gap * 1.75;

            @include breakpoint(medium) {
                margin-bottom: $space-gap * 2.5;
            }
        }
        &-2 {
            margin-bottom: $space-gap * 1.5;

            @include breakpoint(medium) {
                margin-bottom: $space-gap * 1.5;
            }
        }
        &-1 {
            margin-bottom: $space-gap;

            @include breakpoint(medium) {
                margin-bottom: $space-gap;
            }
        }
        &-none {
            margin-bottom: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:58";