body {
    font-family: $base-font;
    font-size: $body-text-m;
    line-height: 1.4;
    color: $color-tertiary;
}

// Font sizes
h1,
.h1 {
    font-size: clamp(2.5rem, 3.5vw, $heading-xl);
    line-height: 1;
    margin-top: 3rem;
}

h2,
.h2 {
    font-size: clamp(1.5rem, 2.5vw, $heading-l);
    line-height: 1.2;
    margin-top: 1rem;
}

h3,
.h3 {
    font-size: $heading-m;
    line-height: 1.2;
    margin-top: 0.75rem;
}

h4,
.h4 {
    font-size: $heading-s;
    line-height: 1.2;
}

h5,
.h5 {
    font-size: $heading-xs;
    line-height: 1.2;
    margin-top: 0.5rem;
}

h6,
.h6 {
    font-size: $heading-xxs;
    line-height: 1.2;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: $color-tertiary;
    font-weight: 800;
}

h1,
h2,
h3,
h4,
h5,
h6,
.rich-text span {
    scroll-margin-top: 10rem;
}

a {
    color: $color-primary;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

p a {
    text-decoration: underline;
}

hr {
    border: none;
    height: $width-border;
    background: $color-line;
    margin: 2rem -1rem;
    width: calc(100% + 2rem);
    @include breakpoint(large) {
        margin: 5rem -1rem;
    }

    &.content-width {
        max-width: $width-container;
    }
    .container--with-sidebar & {
        margin: 2rem 0;
        width: 100%;
    }
}

cite {
    font-style: normal;
    font-weight: bold;
}

ul {
    list-style-type: none;
}

ul li:before {
    content: '-';
    position: absolute;
    font-weight: 800;
    text-indent: -1rem;
}

ul li {
    padding: 0;
}

mark {
    background-color: $color-delete;
}

ins {
    color: $color-insert;
}

del {
    color: $color-delete;
}

small {
    font-size: 0.625rem;
}
blockquote {
    padding: 0.5rem;
    border-left: 0.5rem solid $color-primary;
}

.red {
    color: $color-primary;
}

/* Red emphasis lines */
.block-text {
    position: relative;
}

*:has(.red-emphasis) {
    position: relative;
}

.red-emphasis::before {
    content: '';
    background: url('../img/corner-pop.svg') no-repeat;
    position: absolute;
    top: -0.7rem;
    left: -0.8rem;
    width: 1rem;
    height: 1.5rem;
    transform: rotate(12deg);

    @include breakpoint(large) {
        top: -1.4rem;
        left: -1.8rem;
        width: 2rem;
        height: 3rem;
    }
}

/* Yellow oval highlight */
.yellow-oval {
    display: inline-block;
    position: relative;
    padding: 0 5px;
    overflow: visible;
}

.yellow-oval::before {
    content: '';
    position: absolute;
    background: url('../img/yellow-circle.svg') no-repeat;
    background-size: 100% 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0.25rem;
    pointer-events: none;
    padding: 0.5rem;
    margin: -0.5rem;
    z-index: -1;
}

/* Yellow underline with hand-drawn effect */
.yellow-underline {
    display: inline-block;
    position: relative;
    color: inherit;
    overflow: visible;
}

.yellow-underline::after {
    content: '';
    position: absolute;
    background: url('../img/yellow-underline.svg') no-repeat;
    background-size: 100% 100%;
    height: 50%;
    width: 100%;
    right: 0;
    bottom: -25%;
    pointer-events: none;
    z-index: -1;
}

;@import "sass-embedded-legacy-load-done:12";