.logo {
    width: $width-logo;

    @include breakpoint(medium) {
        width: 5.75rem;
    }

    @include breakpoint(large) {
        width: $width-logo;
    }
}

;@import "sass-embedded-legacy-load-done:33";