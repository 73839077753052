.card {
    border-radius: $space-gap * 0.5;
    box-shadow: 2px 4px 12px -4px rgba($color-black, 0.5);
    overflow: hidden;
    display: flex;
    flex-flow: column wrap;
    height: 100%;
    &__content {
        display: flex;
        flex-flow: column wrap;
        gap: 1rem;
        padding: $space-gap;

        h4 {
            margin: 0;
            font-size: $heading-xs;
            font-weight: 700;
        }
    }

    &-campaign {
        background: $color-white;

        &__image {
            aspect-ratio: 3 / 2;
            width: 100%;
            position: relative;

            img {
                width: 100%;
            }
        }

        .button {
            display: flex;
            margin: 0.5rem 0 0;
            width: 100%;
            padding: 0.75rem 1.25rem;
            line-height: 1;
            min-height: unset;

            &__holder {
                margin-top: auto;
            }
        }
    }
    &-team {
        .card-image {
            max-height: 23.375rem;
            width: 100%;
            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
        .card__content {
            text-align: center;
            padding: 2rem;
        }
        .card__summary {
            gap: 1rem;
            display: flex;
            text-align: center;
            flex-direction: column;
            color: $color-black;
            span {
                display: block;
            }
        }
        h4 {
            font-size: 1.5rem;
        }
    }
    &__job {
        font-weight: 800;
        color: $color-primary;
        margin-bottom: 0.5rem;
    }
    // .social {
    .button--social,
    .button--social .icon,
    .icon {
        color: $color-primary;
        fill: $color-primary;
    }
    // }
    &-stat {
        text-align: left;
        h4 {
            font-size: 3.5rem;
            font-weight: 800;
            line-height: 4.2rem;
            text-align: left;
            color: $color-primary;
            margin-bottom: 0;
        }
        .card__content {
            text-align: left;
            padding: 2rem;
            gap: 2;
        }
        &__red {
            background-color: $color-primary;
            color: $color-white;
            h4 {
                color: $color-white;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:60";