.list-item {
    width: 100%;

    .list-item__image {
        width: 100%;
        max-height: 19.75rem;
        @include breakpoint(large) {
            width: 19.75rem;
            max-height: none;
        }

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    .list-item__content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @include breakpoint(medium) {
            width: 100%;
            flex: 1;
        }
        .h3 {
            color: $color-black;
            margin-left: 0;
            margin-bottom: 0;
            &:hover {
                color: $color-black;
            }
        }
        p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.4;
        }

        .button {
            margin: 1rem 0;
        }
    }
    &__meta {
        font-size: 14px;
        line-height: 1.4;
        color: $color-grey;
    }
}

;@import "sass-embedded-legacy-load-done:36";