.header-sticky {
    padding-top: 0 !important;
}
.header-wrapper {
    transition: all 0.3s ease-in-out;
    ~ .messages {
        margin: 0;
    }

    @include breakpoint(large) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        background: unset;

        &:has(~ .messages) {
            padding-top: 3.4rem;
        }
    }

    @include breakpoint(medium) {
        ~ .messages {
            margin: 0 0 -4rem;
            z-index: 20;
            position: relative;
        }
    }

    background-color: $color-tertiary;
    background-repeat: no-repeat;
    background-size: cover;

    &.homepage-header {
        background-color: unset;

        @include breakpoint(medium) {
            width: 100%;
        }

        &.overlay::after {
            display: none;
        }
    }

    .header-text {
        color: $color-white;
        text-align: center;
        position: relative;
        z-index: 10;
        padding: 5rem 1.5rem 5rem 1.5rem;
        max-width: $width-container;
        margin: 0 auto;

        h1 {
            color: $color-white;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.25rem;
            margin-top: 1rem;
        }

        @include breakpoint(medium) {
            padding: 7.5rem 1.5rem 5rem 1.5rem;
            p {
                font-size: 1.5rem;
            }
        }
    }
}
.header-wrapper.homepage-header + .header-banner {
    display: none !important;
}

.no-image {
    background-color: $color-tertiary;
    background: $color-tertiary;
    color: $color-white;
}

.header-banner {
    min-height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        color: $color-white;
        margin-bottom: 1rem;
    }

    &.overlay {
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: hsla(0, 0, 0, 0);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &[class*='opacity-1']::after {
        background-color: hsla(199, 68%, 11%, 0.1);
    }
    &[class*='opacity-2']::after {
        background-color: hsla(199, 68%, 11%, 0.2);
    }
    &[class*='opacity-3']::after {
        background-color: hsla(199, 68%, 11%, 0.3);
    }
    &[class*='opacity-4']::after {
        background-color: hsla(199, 68%, 11%, 0.4);
    }
    &[class*='opacity-5']::after {
        background-color: hsla(199, 68%, 11%, 0.5);
    }
    &[class*='opacity-6']::after {
        background-color: hsla(199, 68%, 11%, 0.6);
    }
    &[class*='opacity-7']::after {
        background-color: hsla(199, 68%, 11%, 0.7);
    }
    &[class*='opacity-8']::after {
        background-color: hsla(199, 68%, 11%, 0.8);
    }
    &[class*='opacity-9']::after,
    &.opacity-100::after {
        background-color: hsla(199, 68%, 11%, 0.9);
    }

    .header-text {
        color: $color-white;
        text-align: center;
        position: relative;
        z-index: 10;
        padding: 5rem 1.5rem 5rem 1.5rem;
        max-width: $width-container;
        margin: 0 auto;

        @include breakpoint(medium) {
            padding: 7.5rem 1.5rem 5rem 1.5rem;
            padding-top: 15rem;
            p {
                font-size: 1.5rem;
            }
        }
    }
}

.language-switcher {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-left: auto;

    &__toggle {
        user-select: none;
        color: $color-tertiary;
        font-size: 1rem !important;
        font-weight: 400 !important;
        line-height: 1 !important;

        &:hover {
            font-weight: 700;
            cursor: pointer;
        }
    }
    .language-controls {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: normal;
        transition: all 0.5s ease-in-out;
        max-width: 0;
        padding: 0;
    }
}

#lang_toggle,
#mobile_lang_toggle {
    &:checked {
        ~ .language-controls {
            margin-left: 1rem;
            border-left: 1px solid $color-medium-grey;
            padding-left: 1rem;
            max-width: 50rem;
        }
    }
}

.header {
    position: relative;
    z-index: 11;
    background: $color-white;

    &:has(~ .messages) {
        padding-top: 4rem;
    }

    nav {
        display: flex;
        align-items: center;
        gap: 1.5rem;
    }

    .button {
        margin: 0;
    }

    a:no-button,
    label {
        user-select: none;
        color: $color-tertiary;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.4;
    }

    .header-rule-wrapper {
        flex: 1;
    }
    .box {
        box-shadow: 4px 4px 5px -2px #00000040;
    }
}
.header-rule-wrapper {
    flex: 1;
    .header-rule {
        height: 1px;
        background: $color-tertiary;
        width: 100%;
        margin: 0 !important;
    }
}

.header-controls {
    .icon-button {
        background: transparent;

        .icon {
            width: 3rem;
            height: 3rem;
        }
    }
}

.header-mobile {
    display: none;
    background: $color-white;
    flex-flow: column nowrap;
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;

    > * {
        flex-shrink: 0;
        z-index: 100;
    }

    &__navigation {
        flex: 1;
        overflow: auto;
    }

    .icon-button {
        background: $color-tertiary;

        .icon {
            width: 3rem;
            height: 3rem;

            #Shape {
                fill: $color-tertiary;
            }
        }
    }

    .header-mobile__home-link {
        border-top: $width-border solid $color-primary-darker;
    }
}

.header-desktop {
    a {
        color: $color-white;
    }
}

#nav_toggle {
    display: block;
    @include breakpoint(small) {
        display: none;
    }
}
// Nav toggled styles
#nav_toggle:checked {
    ~ .header-controls {
        display: none;
    }

    ~ .header-mobile {
        display: flex;
    }
}

a[href^='/accounts/login/'],
a[href^='/accounts/logout/'] {
    padding-right: 1.5rem;
    background: url(../sprite/login.svg) no-repeat right;
    background-size: contain;
}

;@import "sass-embedded-legacy-load-done:16";