.hidden {
    @include hidden;
}

.visually-hidden {
    @include visually-hidden;
}

.unstyled-list {
    @include unstyled-list;
}

// Backgrounds
.white-bg {
    background: $color-white;
    color: $color-grey;
}
.black-bg {
    background: $color-black;
    color: $color-white;
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6,
    a,
    p {
        color: $color-white;
    }
}

.light-bg {
    background: $color-light-grey;
}

.medium-bg {
    background: $color-medium-grey;
    color: $color-black;
}

.dark-bg {
    background: $color-background;
    color: $color-white;
}

.primary-bg {
    background: $color-primary;
    color: $color-white;
}
.secondary-bg {
    background: $color-secondary;
}

.opaque-bg {
    background: rgba($color-black, 0.3);
    color: $color-white;
}

.background-cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    margin: 0 !important;
    padding: 0 !important;
    z-index: -2;
}

// Width / positioning
.display-block {
    display: block;
}

.small {
    max-width: $width-block;
}

.large {
    max-width: $width-container;
}

.homepage .small {
    max-width: $width-block;
}

.homepage .large {
    max-width: $width-container;
}

.no-max {
    max-width: none !important;
}

.relative {
    position: relative;
}

// Flex utilities
.flex-1 {
    flex: 1;
}

// Spacing
.margin-auto {
    margin: auto;
}

.margin-0 {
    margin: 0;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-left {
    margin-left: math.div($space-gap, 2);
}

.margin-left-0 {
    margin-left: 0;
}

.margin-left-1 {
    margin-left: $space-gap;
}

.margin-left-2 {
    margin-left: $space-gap * 2;
}

.margin-right-auto {
    margin-right: auto;
}

.margin-right {
    margin-right: math.div($space-gap, 2);
}

.margin-right-0 {
    margin-right: 0;
}

.margin-right-1 {
    margin-right: $space-gap;
}

.margin-right-2 {
    margin-right: $space-gap * 2;
}

.margin-top-auto {
    margin-top: auto;
}

.margin-top {
    margin-top: math.div($space-gap, 2);
}

.margin-top-0 {
    margin-top: 0;
}

.margin-top-1 {
    margin-top: $space-gap;
}

.margin-top-2 {
    margin-top: $space-gap * 2;
}

.margin-top-3 {
    margin-top: $space-gap * 3;
}

.margin-bottom-auto {
    margin-bottom: auto;
}

.margin-bottom {
    margin-bottom: math.div($space-gap, 2);
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-bottom-1 {
    margin-bottom: $space-gap;
}

.margin-bottom-2 {
    margin-bottom: $space-gap * 2;
}

.margin-bottom-3 {
    margin-bottom: $space-gap * 3;
}

.margin-bottom-5 {
    margin-bottom: $space-gap * 5;
}

.margin-right-1 {
    margin-right: $space-gap;
}

// Text control
.text-align-center {
    text-align: center;
    display: block;
}
.text-align-left {
    text-align: left;
    display: block;
}
.text-align-right {
    text-align: right;
    display: block;
}

.font-size-xxs {
    font-size: $body-text-xxs;
}

.font-size-xs {
    font-size: $body-text-xs;
}

.font-size-s {
    font-size: $body-text-s;
}

.font-size-m {
    font-size: $body-text-m;
}

.font-size-l {
    font-size: $body-text-l;
}

.font-size-xl {
    font-size: $body-text-xl;
}

.font-size-xxl {
    font-size: $body-text-xxl;
}

.font-bold {
    font-weight: 700;
}

.font-italic {
    font-style: italic;
}

// Stylistic
.cursor-pointer {
    cursor: pointer;
    user-select: none;
}

.shadow {
    box-shadow: 0px math.div($space-gap, 3) #{$space-gap * 0.75} 0px rgba($color-black, 0.1);
}

.border {
    border: $width-border solid $color-line;

    &--light {
        border: $width-border solid $color-light-grey;
    }

    &--bottom {
        border-bottom: $width-border solid $color-line;
    }

    &--top {
        border-top: $width-border solid $color-line;
    }
}

.color-primary {
    color: $color-primary;
}

.color-medium-grey {
    color: $color-medium-grey;
}

.color-action {
    color: $color-action;
}
.break-word {
    // These are technically the same, but use both
    overflow-wrap: break-word;
    word-wrap: break-word;

    // This is the dangerous one in WebKit, as it breaks things wherever
    word-break: break-all;
    // Instead use this non-standard one
    word-break: break-word;

    // Adds a hyphen where the word breaks, if supported (No Blink)
    hyphens: auto;
}

;@import "sass-embedded-legacy-load-done:10";