.index__results {
    position: relative;
    .h3 {
        margin-top: 0;
        margin-left: 0.5rem;
    }
    h4 {
        font-style: normal;
        margin: 0 0 0.5rem 0;
    }
}
#filter_form {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 2rem 1rem;
    max-width: 79.5rem;
    margin: 0 auto;
    @include breakpoint(enormous) {
        padding: 4rem 0 0;
    }

    h2 {
        font-style: normal;
        margin: 0 0 2.25rem 0;
    }
    form {
        position: relative;
    }
    .search-button {
        position: absolute;
        top: 0.25rem;
        right: 0;
        background: none;
        margin: 0;
        .icon {
            fill: $color-black;
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .container--with-sidebar {
        flex: 1;
    }
    .filter-container {
        margin-left: 1rem;
        margin-right: 1rem;
        .icon {
            &.icon--close {
                display: none;
            }
        }
    }
    .filter-dropdown {
        display: none;
        margin: 0 1rem;
        h3 {
            display: none;
        }

        @include breakpoint(large) {
            display: block;
            margin-top: 0;
            h3 {
                display: block;
            }
            #field_id_query:not(.active) label {
                color: $color-grey;
                font-weight: 400;
            }
        }
    }
    #filter-dropdown-checkbox:checked {
        ~ .filter-dropdown {
            display: block;
            padding-top: 2rem;
            @include breakpoint(large) {
                padding-top: 0;
            }
            #field_id_category,
            #field_id_tags {
                margin-top: 0;
            }
        }
        ~ label .icon {
            &.icon--open {
                display: none;
            }
            &.icon--close {
                display: inline;
            }
        }
    }
}
.field--select {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 1.2rem;
        right: 2rem;
        background: $color-white;
        border: solid $color-black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 0.2rem;
        transform: rotate(45deg);
        pointer-events: none;
    }
    select {
        appearance: none;
        -moz-appearance: none;
    }
    .field-wrapper__multiselect & {
        select {
            border-radius: 1.5rem;
            padding-right: 2rem;
        }
        &:after {
            content: none;
        }
    }
}

a.card-link {
    color: $color-tertiary;
    display: block;

    &:hover {
        text-decoration: none;
    }
}

.card {
    border-radius: $space-gap * 0.5;
    box-shadow: 2px 4px 12px -4px rgba($color-black, 0.5);
    overflow: hidden;
    display: flex;
    flex-flow: column wrap;
    height: 100%;

    &-campaign {
        background: $color-white;

        &__image {
            aspect-ratio: 3 / 2;
            width: 100%;
            position: relative;

            img {
                width: 100%;
            }
        }

        &__content {
            display: flex;
            flex-flow: column wrap;
            gap: 1rem;
            flex: 1;
            padding: $space-gap;

            h4 {
                margin: 0;
                font-size: $heading-xs;
                font-weight: 700;
            }
        }

        .button {
            display: flex;
            margin: 0.5rem 0 0;
            width: 100%;
            padding: 0.75rem 1.25rem;
            line-height: 1;
            min-height: unset;

            &__holder {
                margin-top: auto;
            }
        }
    }
}

.pagination {
    .button {
        margin: 0 0 0.25rem 0.5rem;
    }

    > .button {
        .icon {
            margin: 0 !important;
        }
    }
}

;@import "sass-embedded-legacy-load-done:22";