.pebbletrail {
    .pebbletrail-link {
        display: inline-flex;
        align-items: center;
        color: $color-grey;
        text-decoration: underline;
        font-weight: 400;
        font-size: 14px;
        &.active {
            font-weight: 700;
            text-decoration: none;
        }
        .icon {
            margin-right: math.div($space-gap, 4);
        }
    }

    .pebbletrail-separator {
        color: $color-medium-grey;
        .icon {
            height: 14px;
            width: 14px;
        }
    }
}
.pebbletrail-container {
    max-width: 108rem;
    margin: 0 auto;
}

// main menu
.main-menu,
.top-main-menu ul {
    padding: 0 1rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0.7rem;

    @include breakpoint(medium) {
        gap: 1.2rem;
    }

    @include breakpoint(large) {
        gap: 1.5rem;
        flex-direction: row;
        align-items: center;
    }

    li {
        list-style: none;
        &::before {
            content: '';
        }
    }
    .block-link {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        border-bottom: 1px solid $color-black;
        width: 100%;

        @include breakpoint(large) {
            border-bottom: none;
            padding: 0;
            width: auto;
        }

        a {
            display: flex;
            flex-flow: row wrap;
            flex: 100%;
            align-items: center;
            color: $color-black;
            padding: 1rem 0;
            &:hover {
                font-weight: 700;
                text-decoration: none;
            }
        }
        &:has(.header-sub-menu) {
            a {
                padding: 0;
            }
        }

        .header-sub-menu {
            display: none;
            position: relative;
            flex-flow: column;
            flex: 100%;
            padding-bottom: 1rem;

            @include breakpoint(large) {
                position: absolute;
                top: 100%;
                left: -1.5rem;
                background: $color-lightest-grey;
                border-radius: 0 0 0.5rem 0.5rem;
                padding: 1rem 0.5rem;
                transform: translate(0, 31px);
                min-width: 9rem;
                box-shadow: 4px 4px 5px -2px rgba(0, 0, 0, 0.2509803922);

                &:before {
                    content: '';
                    border: $color-lightest-grey 12px solid;
                    border-color: transparent transparent $color-lightest-grey
                        transparent;
                    position: absolute;
                    top: -24px;
                    left: 1rem;
                }
            }

            &-item {
                @include breakpoint(large) {
                    width: 100%;
                }
                a {
                    display: block;
                    padding: 0.5rem 0;
                    line-height: 1;

                    &:hover {
                        background: $color-white;
                    }

                    @include breakpoint(large) {
                        padding: 0.5rem 1rem;
                        width: auto;
                    }
                }
            }
        }
        button {
            padding: 1rem;
            margin-left: auto;
            border-radius: 0;
            min-height: 1.5rem;
            background-color: $color-lightest-grey;

            &:hover {
                background-color: darken($color-lightest-grey, 5%);
            }

            @include breakpoint(large) {
                background-color: unset;
                padding: 0.5rem;
                margin-left: 0.25rem;
                border-radius: 0.5rem;

                &:hover {
                    background-color: $color-lightest-grey;
                }
            }
        }
        .icon {
            position: relative;
            width: 0.75rem;
            height: 0.75rem;
            margin: 0;

            &.close {
                display: none;
            }

            &.open {
                display: block;
            }

            @include breakpoint(large) {
                width: 0.5rem;
                height: 0.5rem;
            }
        }

        ~ .button {
            margin-top: 1rem;
            width: 100%;

            @include breakpoint(large) {
                margin-top: 0;
                width: unset;
            }
        }

        &.active {
            .header-sub-menu {
                display: flex;
            }

            .icon {
                &.open {
                    display: none;
                }

                &.close {
                    display: block;
                }
            }
        }
    }
}

.top-main-menu {
    border-bottom: 1px solid $color-tertiary;
    padding: 0.25rem;
    @include breakpoint(large) {
        border-bottom: none;
    }

    ul {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
    }
    a {
        color: $color-tertiary;
        &:hover {
            color: $color-tertiary;
            font-weight: 700;
            text-decoration: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:34";