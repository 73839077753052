.block {
    margin: auto;
    @include breakpoint(large) {
        margin: auto;
    }

    &:first-of-type:not(.cta-block) {
        margin-top: 0;
    }

    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        position: relative;
    }
}
.block-text {
    z-index: 10;
}
.block.rich-text {
    position: relative;

    h2,
    h3,
    h4 {
        margin-bottom: 0;
    }
    p {
        margin-top: 1rem;
    }
}

@import 'columns';
@import 'tile';
@import 'concertina';
@import 'carousel';
@import 'image';
@import 'quote';
@import 'html';
@import 'top_pages';
@import 'content_list';
@import 'cta';
@import 'form_block';
@import 'highlight';
@import 'section';
@import 'single_line_callout';
@import 'separator';
@import 'map';
@import 'card';

;@import "sass-embedded-legacy-load-done:40";