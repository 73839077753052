.right-sidebar__content {
    max-width: $width-text;
    margin: 0 auto;
    padding: 2rem 5.5rem 2rem 2.5rem;
    @include breakpoint(large) {
        max-width: 15rem;
    }
    h3 {
        font-size: 1.188rem;
        text-transform: none;
        line-height: 1.2;
    }
    .button--action {
        margin: 0.5rem 0;
        padding: 0.5rem;
        font-size: 0.9375rem;
        .icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
.meta {
    .button--light {
        margin: 0.5rem 1rem 0 0 !important;
    }
}

.highlightpage {
    .highlight-intro {
        h1 {
            margin-bottom: 1rem;
        }
        margin: auto;
        padding: 5rem 1.5rem 0;
        font-size: 1.5rem;
    }
}

;@import "sass-embedded-legacy-load-done:24";