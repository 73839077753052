.button,
button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
    user-select: none;
    position: relative;
    margin: 1rem 2rem;
    border-radius: 50rem;
    padding: 1rem 2rem;
    line-height: 1;
    min-height: 2.875rem;
    text-transform: uppercase;
    transition: all 0.2s ease;
    border: none;
    background: none;
    color: $color-primary;

    .icon:first-child {
        margin-right: math.div($space-gap, 2);
    }

    .icon:last-child {
        margin-left: math.div($space-gap, 2);
    }

    &.icon-button {
        height: 2.5rem;
        width: 2.5rem;

        .icon {
            margin: 0;
            padding: 0.2rem;
        }
    }

    &--action {
        background: $color-action;

        &:hover {
            background: $color-action-darker;
        }
    }

    &--secondary {
        background: $color-white;
        color: $color-black;
        border: 2px solid $color-black;

        &:hover {
            background: $color-black;
            color: $color-white;
        }
    }

    &--solid {
        border: none;

        &-red {
            background: $color-primary;
            color: $color-white;

            &:hover {
                background: $color-primary-lighter;
                color: $color-white;
                text-decoration: none;
            }
        }
        &-aqua {
            background: $color-secondary-darker;
            color: $color-white;
            &:hover {
                background: $color-secondary;
                color: $color-tertiary;
                text-decoration: none;
            }
        }
        &-navy {
            background: $color-tertiary;
            color: $color-white;

            &:hover {
                background: $color-tertiary-lighter;
                color: $color-white;
                text-decoration: none;
            }
        }
        &-white {
            background: $color-white;
            color: $color-tertiary;

            &:hover {
                background: $color-lightest-grey;
                color: $color-tertiary;
                text-decoration: none;
            }
        }
    }

    &--outlined {
        background: none;

        &-red {
            border: 2px solid $color-primary;
            color: $color-primary;

            &:hover {
                background: $color-primary;
                color: $color-white;
                text-decoration: none;
            }
        }
        &-aqua {
            border: 2px solid $color-secondary;
            color: $color-secondary;

            &:hover {
                background: $color-secondary;
                color: $color-tertiary;
                text-decoration: none;
            }
        }
        &-navy {
            border: 2px solid $color-tertiary;
            color: $color-tertiary;

            &:hover {
                background: $color-tertiary;
                color: $color-white;
                text-decoration: none;
            }
        }
        &-white {
            border: 2px solid $color-white;
            color: $color-white;

            &:hover {
                background: $color-white;
                color: $color-tertiary;
                text-decoration: none;
            }
        }
    }

    &--large {
        font-size: 1.25rem;
        padding: 1rem 2rem;
    }

    &--small {
        font-size: 0.875rem;
        padding: 0.75rem 1.25rem;
    }

    &--center {
        justify-content: center;
    }

    &--margin-0 {
        margin: 0;
    }

    &--light {
        background: $color-light-grey;
        margin: 0.2rem;
        color: $color-black;
        font-size: 0.75rem;
        padding: 0.3rem 0.5rem;

        .icon {
            fill: $color-black;
            width: 1rem;
            height: 1rem;
        }
        &:hover {
            background: $color-black;
            color: $color-white;
        }
    }

    &--social {
        background: none;
        margin: 0.2rem;
        width: 1.5rem;
        height: 1.5rem;
        color: $color-primary;
        font-style: italic;

        .icon {
            fill: $color-primary;
            width: 1rem;
            height: 1rem;
        }
        &:hover {
            background: none;
            .icon {
                fill: $color-primary-darker;
            }
        }
    }
    &--pagination,
    &--pagination-active {
        background: $color-light-grey;
        color: $color-black;
        font-weight: 400;
        line-height: 1.4;
        text-align: center;
        padding: 0.5rem 0.875rem;
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 0.25rem;
        min-height: unset;

        &:hover {
            background: #b3bbbf;
            color: $color-black;
            text-decoration: none;
        }
    }

    &--pagination-active {
        background: $color-tertiary;
        color: $color-white;
    }

    &--disabled {
        pointer-events: none;
        background: $color-lightest-grey;
        border: none;
        color: $color-grey;
    }
}

.button-row {
    margin-top: 1rem;

    .button + .button {
        margin-left: math.div($space-gap, 2);
    }

    .button {
        margin-bottom: 0.2rem;
        margin-top: 0.2rem;
        margin-left: 0;
        margin-right: 0;
    }

    &.row--justify-left {
        .button:first-child {
            margin-left: 0;
        }
    }

    &.row--justify-end {
        .button:last-child {
            margin-right: 0;
        }
    }
}

.donate-cta-block .button {
    background-color: $color-secondary-darker;
    border: none;
    width: 100%;
    font-size: 1.25rem;
    margin: 1rem 0;
    padding: 1.5rem;
    color: $color-white;
    &:hover {
        background: $color-secondary;
        color: $color-tertiary;
        text-decoration: none;
    }
}

.button-join {
    background: $color-tertiary;
    border-radius: 50rem;
    font-weight: 700;
    text-transform: capitalize;
    padding: 0rem 1rem;
    margin: -1px 0;
    width: 100%;
    border: none;
    position: relative;

    a {
        color: $color-white;
    }
    a:hover {
        text-decoration: none;
    }
}

footer .button-join {
    margin-top: 1rem;
    background: rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
}

;@import "sass-embedded-legacy-load-done:30";