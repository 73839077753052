.single-line-callout {
    font-size: $body-text-xl;
    padding: $space-gap $space-gap * 1.5;

    p {
        margin: 0;
    }

    &.background-red {
        background-color: $color-primary;
        color: $color-white;

        a {
            color: $color-white;
            text-decoration: none;
        }
    }
    &.background-aqua {
        background-color: $color-secondary;
        color: $color-tertiary;

        a {
            color: $color-tertiary;
        }
    }
}

;@import "sass-embedded-legacy-load-done:57";