.top-pages-block {
    > .row {
        gap: 1rem;
    }

    .top-pages__title {
        margin-bottom: 0;
    }

    .view-all-button {
        margin: 0;
    }

    .masonry-grid {
        margin-top: 2.5rem;
    }
}

;@import "sass-embedded-legacy-load-done:51";