.image-background-banner {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: clamp(3rem, 6vw, 7.5rem) 1.5rem;

    &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: rgba($color-tertiary, 0.7);
        z-index: -1;
    }

    .banner-content__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: $width-block;

        .banner-content {
            padding-top: 0;
            color: $color-white;
            text-align: center;
            gap: clamp(0.5rem, 1.3vw, 1.5rem);

            .header,
            .banner-content {
                position: relative;
                color: $color-white;
            }

            .banner-content__header {
                z-index: 1;
                h1.header {
                    background: none;
                }

                @include breakpoint(medium) {
                    min-height: auto;
                    width: auto;
                }
            }

            .sub-header {
                margin: 0;
                text-transform: none;
                font-size: clamp(1rem, 1.3vw, 1.5rem);
                color: $color-white;
            }

            .content {
                margin: 0;
                p {
                    font-size: clamp(1rem, 1.3vw, 1.5rem);
                }
            }

            .banner-content__buttons {
                z-index: 1;
                margin-top: 0;
                @include breakpoint(medium) {
                    margin-top: unset;
                    .button {
                        font-size: 1.375rem;
                        padding: 1rem;
                    }
                }
            }
        }

        &.text-align-center {
            .rich-text {
                > * {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    .image-block__credit {
        top: 0;
        bottom: auto;
    }

    &--block {
        margin-left: -$space-gap;
        margin-right: -$space-gap;

        &:first-child {
            margin-top: -$space-gap;
        }
    }
    .image-block__credit {
        top: 0;
        bottom: unset;
        right: 0;
    }

    &.home-page__banner {
        padding: 0.5rem 0.5rem;

        @include breakpoint(large) {
            flex: 0 0 50%;
            padding: clamp(2.5rem, 4vw, 5rem);
            padding-top: 15rem;
        }

        &:after {
            background-color: transparent;
        }

        .background-image {
            background-position: bottom right;
            right: -50%;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                background: linear-gradient(
                    180deg,
                    rgba($color-white, 0.95) 30%,
                    rgba($color-white, 0.9) 50%,
                    rgba($color-white, 0.75) 90%,
                    rgba($color-white, 0) 100%
                );
                height: 50%;

                @include breakpoint(medium) {
                    width: 50%;
                    height: unset;
                    right: unset;
                    bottom: 0;
                    background: linear-gradient(
                        90deg,
                        rgba($color-white, 0.95) 30%,
                        rgba($color-white, 0.9) 50%,
                        rgba($color-white, 0.75) 90%,
                        rgba($color-white, 0) 100%
                    );
                }
            }

            @include breakpoint(medium) {
                background-position: bottom center;
                right: 0;
            }
        }

        .banner-content__wrapper {
            max-width: $width-container;

            .banner-content {
                flex: 1;
                margin-right: auto;
                border-radius: 0.5rem;
                color: $color-tertiary;
                padding: clamp(1.5rem, 4vw, 5rem);
                text-align: left;
                margin-bottom: 100%;
                transition: all 0.5s ease;

                @include breakpoint(medium) {
                    flex: 0 0 40%;
                    margin-bottom: 0;
                }

                @include breakpoint(large) {
                    padding: 0;
                }

                &.dark {
                    background: rgba($color-tertiary, 0.7);
                    .banner-content__header h1.header,
                    .sub-header,
                    .content p {
                        color: $color-white;
                    }
                }

                .banner-content__header {
                    h1.header {
                        color: $color-tertiary;
                        font-size: clamp(1.5rem, 3vw, 3.5rem);
                        line-height: 1.2;
                    }
                }

                .sub-header {
                    color: $color-tertiary;
                }
            }
        }

        .image-overlay-banner {
            position: relative;
            margin-bottom: 2rem;
            top: -12rem;
            margin-bottom: -10rem;
            padding-top: 12rem;
            .image-block__credit {
                display: none;
            }
        }
    }
}

// nth-child needed as there is a hidden h1 in the dom.
.page-sidebar + .page-main .image-overlay-banner--block:nth-child(2) {
    margin-top: -$space-gap * 3;
}

;@import "sass-embedded-legacy-load-done:35";