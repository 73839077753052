.homepage .highlight-grid {
    .highlight-card {
        max-width: 400px;
    }
    .highlight__image {
        height: 198px;
        width: 100%;
        object-fit: cover;
    }
}

.highlight-grid {
    .grid--four {
        .grid-item {
            margin-bottom: 1.5rem;
        }

        .grid-sizer {
            width: 100%;

            @include breakpoint(medium) {
                width: 48%;
            }

            @include breakpoint(large) {
                width: calc((100% - 4.5rem) / 4);
            }
        }
    }
    .grid--three {
        .grid-item {
            margin-bottom: 1.5rem;
        }

        .grid-sizer {
            width: 100%;

            @include breakpoint(medium) {
                width: 48%;
            }

            @include breakpoint(large) {
                width: calc(
                    (100% - 3rem) / 3
                ); // Subtract the two 24px/1.5rem gaps, then divide by 3
            }
        }
    }

    .grid--two {
        .grid-item {
            margin-bottom: 1.5rem;
        }

        .grid-sizer {
            width: 100%;

            @include breakpoint(large) {
                width: 48%;
            }
        }
    }
}

.highlight-grid a {
    &:hover {
        text-decoration: none;
    }
}

.highlight-card {
    box-shadow: 4px 4px 5px -2px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    max-width: 28rem;
    margin: 0 auto;
    background-color: $color-white;
}

.highlight__image {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    width: 100%;
}

.highlight--left {
    @include breakpoint(medium) {
        display: flex;
        max-width: unset;

        .highlight__image {
            object-fit: cover;
            width: 10rem;
            border-bottom-left-radius: 0.5rem;
            border-top-right-radius: 0;
        }
    }

    @include breakpoint(large) {
        .highlight__image {
            width: 12rem;
        }
    }

    @include breakpoint(enormous) {
        .highlight__image {
            width: 15rem;
        }
    }
}

.highlight__content {
    padding: 2rem;

    h3 {
        font-size: 1.5rem;
    }
}

.highlight__meta {
    font-size: 1rem;
    font-weight: 700;
    color: $color-primary;
    line-height: 1.2;
}

.highlight__description {
    color: $color-black;
}

.highlightindexpage .highlight-grid {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @include breakpoint(large) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
}

;@import "sass-embedded-legacy-load-done:55";