.cta-block {
    display: flex;
    flex-flow: column;
    transition: all 0.5s ease;

    @include breakpoint(medium) {
        flex-flow: row;
    }

    &.third {
        .cta-block__body {
            > * {
                max-width: none;
            }
        }
        @include breakpoint(medium) {
            .cta-block__background-image {
                width: 33%;
                flex-shrink: 0;
            }
        }
    }

    &.half {
        @include breakpoint(medium) {
            .cta-block__body {
                flex-shrink: 2.5;
            }
            .cta-block__background-image {
                flex: 1;
                min-width: 50%;
            }
        }
    }

    &.two-thirds {
        @include breakpoint(medium) {
            .cta-block__body {
                min-width: 33%;
                flex-shrink: 2;
                padding: 4rem 3rem;
            }
        }
    }
}

.cta-block__background-image {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 10;
    }

    img {
        height: 100%;
        object-fit: cover;
    }
    @include breakpoint(large) {
        margin: 0;
        .image-block__credit {
            bottom: 0;
            right: 0;
        }
    }
}
.cta-block__buttons {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;

    .button {
        margin: 0;
    }
}

.cta-block-direction-right {
    .cta-block__body {
        align-items: flex-end;
    }
}
.cta-block__body {
    padding: 2.5rem;
    font-size: 1rem;
    color: #2a2d34;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    &-content {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;

        @include breakpoint(medium) {
            gap: 1.5rem;
        }
    }

    @include breakpoint(medium) {
        width: 100%;
        padding: 4rem 3rem;
    }
    @include breakpoint(large) {
        padding: 3rem 5rem;
    }

    h2,
    h4,
    p {
        margin: 0;
    }
    h4 {
        font-size: 1rem;

        @include breakpoint(enormous) {
            font-size: 1.5rem;
        }
    }
    > * {
        max-width: 33rem;
        margin: 0;
    }
}
.cta-block-style-white {
    .cta-block__background-image {
        &::before {
            background: linear-gradient(
                0deg,
                $color-white 0%,
                rgba($color-white, 0) 50%
            );

            @include breakpoint(medium) {
                background: linear-gradient(
                    -90deg,
                    $color-white 0%,
                    rgba($color-white, 0) 50%
                );
            }
        }
    }

    &.cta-block-direction-right {
        .cta-block__background-image {
            &::before {
                background: linear-gradient(
                    180deg,
                    $color-white 0%,
                    rgba($color-white, 0) 50%
                );

                @include breakpoint(medium) {
                    background: linear-gradient(
                        90deg,
                        $color-white 0%,
                        rgba($color-white, 0) 50%
                    );
                }
            }
        }
    }
    .cta-block__body {
        background: $color-white;
    }
}
.cta-block-style-dark_blue {
    .cta-block__background-image {
        &::before {
            background: linear-gradient(
                0deg,
                $color-tertiary 0%,
                rgba($color-tertiary, 0) 50%
            );

            @include breakpoint(medium) {
                background: linear-gradient(
                    -90deg,
                    $color-tertiary 0%,
                    rgba($color-tertiary, 0) 50%
                );
            }
        }
    }

    &.cta-block-direction-right {
        .cta-block__background-image {
            &::before {
                background: linear-gradient(
                    180deg,
                    $color-tertiary 0%,
                    rgba($color-tertiary, 0) 50%
                );

                @include breakpoint(medium) {
                    background: linear-gradient(
                        90deg,
                        $color-tertiary 0%,
                        rgba($color-tertiary, 0) 50%
                    );
                }
            }
        }
    }
    .cta-block__body {
        background: $color-tertiary;
        color: $color-white;

        h2,
        .h2,
        h4,
        .h4 {
            color: $color-white;
        }
    }
}
.cta-block-style-light_grey {
    .cta-block__background-image {
        &::before {
            background: linear-gradient(
                0deg,
                $color-lightest-grey 0%,
                rgba($color-lightest-grey, 0) 50%
            );

            @include breakpoint(medium) {
                background: linear-gradient(
                    -90deg,
                    $color-white 0%,
                    rgba($color-white, 0) 50%
                );
            }
        }
    }

    &.cta-block-direction-right {
        .cta-block__background-image {
            &::before {
                background: linear-gradient(
                    180deg,
                    $color-lightest-grey 0%,
                    rgba($color-lightest-grey, 0) 50%
                );

                @include breakpoint(medium) {
                    background: linear-gradient(
                        90deg,
                        $color-lightest-grey 0%,
                        rgba($color-lightest-grey, 0) 50%
                    );
                }
            }
        }
    }
    .cta-block__body {
        background: $color-lightest-grey;
    }
}
.cta-block-style-light_beige {
    .cta-block__background-image {
        &::before {
            background: linear-gradient(
                0deg,
                $color-beige-lightest 0%,
                rgba($color-beige-lightest, 0) 50%
            );
        }

        @include breakpoint(medium) {
            &::before {
                background: linear-gradient(
                    -90deg,
                    $color-beige-lightest 0%,
                    rgba($color-beige-lightest, 0) 50%
                );
            }
        }
    }

    &.cta-block-direction-right {
        .cta-block__background-image {
            &::before {
                background: linear-gradient(
                    180deg,
                    $color-beige-lightest 0%,
                    rgba($color-beige-lightest, 0) 50%
                );
            }

            @include breakpoint(medium) {
                &::before {
                    background: linear-gradient(
                        90deg,
                        $color-beige-lightest 0%,
                        rgba($color-beige-lightest, 0) 50%
                    );
                }
            }
        }
    }
    .cta-block__body {
        background: $color-beige-lightest;
    }
}

.cta-block-direction-right {
    .cta-block__background-image {
        order: 1;
    }
}

.donate-cta-block .secured-checkout {
    text-align: center;
    font-size: smaller;
}

.column-two:has(.donate-cta-block) .image-block {
    text-align: right;
}

;@import "sass-embedded-legacy-load-done:53";