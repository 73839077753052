* {
    box-sizing: border-box;
}

:first-child {
    margin-top: 0;
}

:last-child {
    margin-bottom: 0;
}

html,
body,
div,
header,
footer,
section,
nav,
main,
aside,
figure,
video,
img {
    max-width: none;
}

body,
html {
    overflow-x: hidden;
}

;@import "sass-embedded-legacy-load-done:5";