.campaign-map-block {
    position: relative;

    .world-map {
        margin: auto;
        max-width: 95vw !important;

        @include breakpoint(large) {
            position: relative;
        }
    }

    .map-outer-container {
        position: relative;
        overflow: hidden;
    }

    .map-wrapper {
        height: 47vw;
    }

    .map {
        width: 100%;
        touch-action: none;
        .datamaps-hoverover {
            display: none !important;
        }

        @include breakpoint(large) {
            .datamaps-hoverover {
                display: block !important;
            }
        }
    }

    .datamap {
        transform: translate(0, 1.5rem);

        @include breakpoint(medium) {
            transform: translate(0, 2.5rem);
        }

        @include breakpoint(enormous) {
            transform: translate(0, -3.5rem);
        }
    }

    // hide map zoom controls on desktop
    .map-zoom {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: column nowrap;
        gap: 0.25rem;
        padding: 1rem;

        .button {
            margin: 0;
            padding: 0.5rem;
            min-height: 1.5rem;
            min-width: 1.5rem;
            background: $color-lightest-grey;
            align-items: center;
            justify-content: center;

            @include breakpoint(large) {
                min-height: 2.5rem;
                min-width: 2.5rem;
            }

            .icon {
                fill: $color-tertiary;
                margin: 0;
                height: 0.85rem;
                width: 0.85rem;

                @include breakpoint(large) {
                    height: 1.25rem;
                    width: 1.25rem;
                }
            }
        }
    }

    .map-header {
        gap: $space-gap;
        margin: auto auto 2rem;
        max-width: $width-container;
        padding: 0 1.5rem;

        @include breakpoint(enormous) {
            padding: 0;
        }

        .stack {
            gap: $space-gap * 0.5;
        }
        h2,
        p {
            margin: 0;
        }
        .button {
            align-self: flex-end;
        }
    }

    .campaigns-container {
        position: absolute;
        top: 0rem;
        right: 1rem;
        left: 1rem;
        background-color: hsla(210, 23%, 95%, 0.8);
        border: 2px solid #e6e9ea;
        border-radius: 0.5rem;
        padding: 1rem 1.5rem;
        height: calc(100% - 1rem);
        transform: translate(0, 0);
        transition: transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

        &.closed {
            transform: translate(120%, 0);
            transition: transform 0.3s cubic-bezier(0.6, -0.28, 0.74, 0.05);
        }

        @include breakpoint(medium) {
            height: calc(100% - 1rem);
            width: 25rem;
            left: unset;
        }

        .icon-button {
            position: absolute;
            padding: 0;
            background: $color-white;
            border-radius: 50%;
            border: 2px solid #e6e9ea;
            height: 28px;
            width: 28px;
            min-height: unset;
            top: -0.5rem;
            right: -2.5rem;
            svg {
                fill: $color-black;
            }
        }
        #js-campaigns-list {
            overflow-y: auto;
            height: calc(100% - 6rem);
            padding-right: 1rem;
            margin-right: -1rem;
            @include breakpoint(large) {
                height: calc(100% - 4rem);
            }
            .card {
                height: auto;
            }
            &::-webkit-scrollbar {
                width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #ccd6de;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #91989e;
                border-radius: 27px;
            }

            /* Handle on hover */
        }
        #js-campaigns-name {
            font-size: 24px;
            font-weight: 800;
            line-height: 28.8px;
            margin: 0 0 0.5rem 0;
        }
        .more-campaigns {
            font-size: 1rem;
            font-weight: 800;
            line-height: 19.2px;
        }
        .card-campaign__content {
            display: block;

            .button:not(.button--solid-aqua, .button--solid-red) {
                display: block;
                padding-right: 0;
                padding-left: 0;
            }
        }
        .card-campaign__summary {
            margin: 1rem 0 0 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:59";