.quote-block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 1.5rem;
    &__content {
        margin-top: 0;
        @include breakpoint(small) {
            margin-top: -0.25rem;
        }
        .h4 {
            text-transform: capitalize;
        }
    }
    cite {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4;
        text-align: left;
        color: $color-black;
        margin-top: 1.5rem;
    }
}

.quote-block__portrait {
    width: 5rem;
    height: auto;
    border-radius: 50rem;
}

.quote-list-block {
    .quote-block {
        margin: 1rem auto 2rem;
    }
}

;@import "sass-embedded-legacy-load-done:49";