.newsletter-form-wrapper {
    color: $color-white;
    margin-top: 1rem;

    @include breakpoint(large) {
        border: none !important;
        width: 100%;
        height: 48px;

        .submit-container {
            background-color: $color-white;
            border-radius: 0 50rem 50rem 0 !important ;
            border: 1px solid $color-black !important;
            border-left: none !important;
            display: flex;
            justify-content: flex-end;

            .footer & {
                background-color: unset;
            }
        }
    }

    input {
        background-color: $color-white;
        color: $color-black;
        outline: none;
        line-height: 3rem;
        border-radius: 50rem;
        border: 1px solid $color-black;
        padding-left: 1.5rem;
        margin-bottom: 0.5rem;
        height: 3rem;
        flex: 1;

        ::placeholder {
            color: $color-black !important;
        }
    }

    .field-label {
        padding-left: 1.5rem;
    }

    .button {
        background: rgba(9, 35, 47, 1);
        border-radius: 50rem;
        font-weight: 700;
        text-transform: capitalize;
        font-size: 1rem;
        padding: 0rem 1rem;
        color: $color-white;
        margin: -1px 0;
        width: 100%;
        border: none;
        position: relative;
        right: -1px;

        @include breakpoint(large) {
            width: auto;
        }

        @include breakpoint(enormous) {
            padding: 0rem 2.5rem;
        }
    }
}

#newsletter-signup {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;

    @include breakpoint(large) {
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        > div {
            flex-grow: 1;

            input[type='text'] {
                border-radius: 50rem 0 0 50rem;
                border: 1px solid $color-black;
                border-right: none;
                margin: 0;
            }
        }
    }
    @include breakpoint(large) {
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    input::placeholder {
        color: $color-white;
    }
    input::placeholder {
        color: $color-white;
    }
}

;@import "sass-embedded-legacy-load-done:37";