.social {
    margin-top: 1.5rem;
    color: $color-white;
}

.social-media-footer-block {
    margin-top: 0.8rem;
    ul {
        margin-top: 0;
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
    }
    .button--social,
    .icon {
        color: $color-white;
        fill: $color-white !important;
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
    h3 {
        color: $color-white;
        font-family: $base-font;
        font-size: 1rem;
        font-weight: 400;
    }
}

;@import "sass-embedded-legacy-load-done:29";