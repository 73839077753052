.tag {
    display: inline-flex;
    align-items: center;
    padding: math.div($space-gap, 2) $space-gap * 0.75;
    background: $color-light-grey;
    color: $color-black;
    border: none;
    border-radius: $border-radius;
    user-select: none;

    .icon:first-child {
        margin-right: math.div($space-gap, 2);
    }

    .icon:last-child {
        margin-left: math.div($space-gap, 2);
    }
}

;@import "sass-embedded-legacy-load-done:31";