#privacy-choices-banner {
    button {
        transform: translate(0.5rem, -5rem);
        padding: 0.5rem 1rem !important;
        min-height: unset !important;
        font-size: 0.8rem !important;
        background: $color-black !important;

        &:hover {
            background: lighten($color-black, 5%) !important;
            text-decoration: none !important;
        }

        @include breakpoint(medium) {
            transform: translate(0.5rem, -0.5rem);
        }
    }
}

@media only screen and (max-width: 400px) {
    #privacy-choices-banner button {
        transform: translate(0.5rem, -6rem);
    }
}

#privacy-choices-settings {
    h3,
    h4 {
        color: $color-white;
    }
    button {
        padding: 0.5rem 1rem !important;
        font-size: 0.8rem !important;
        min-height: unset !important;

        &:hover {
            background: $color-primary-lighter !important;
            text-decoration: none !important;
        }
    }
}
#privacy-choices-prompt {
    display: none !important;
    h3 {
        color: $color-white;
    }
}

;@import "sass-embedded-legacy-load-done:38";