.section-block {
    &.background {
        &-transparent {
            background: transparent;
        }
        &-white {
            background: $color-white;
        }
        &-light_grey {
            background: $color-lightest-grey;
        }
        &-light_beige {
            background: $color-beige-lightest;
            .red {
                color: #db0037;
            }
            a:not(.button) {
                color: #db0037;
            }
            .card__job {
                color: #db0037;
            }
        }
    }

    .container {
        display: flex;
        flex-flow: column;
        gap: 2.5rem;
        transition: all 0.5s ease;

        @include breakpoint(medium) {
            gap: 5rem;
        }

        &.width {
            &-full {
                padding: 0;
                max-width: $width-full;
            }
            &-large {
                padding: 0 1.5rem;
                max-width: $width-container;
            }
            &-medium {
                padding: 0 1.5rem;
                max-width: $width-block;
            }
            &-small {
                padding: 0 1.5rem;
                max-width: $width-text;
            }
        }

        &.padding-top {
            &-6 {
                padding-top: $space-gap * 3.25;

                @include breakpoint(medium) {
                    padding-top: $space-gap * 7.5;
                }
            }
            &-5 {
                padding-top: $space-gap * 2.5;

                @include breakpoint(medium) {
                    padding-top: $space-gap * 5;
                }
            }
            &-4 {
                padding-top: $space-gap * 2;

                @include breakpoint(medium) {
                    padding-top: $space-gap * 4;
                }
            }
            &-3 {
                padding-top: $space-gap * 1.75;

                @include breakpoint(medium) {
                    padding-top: $space-gap * 2.5;
                }
            }
            &-2 {
                padding-top: $space-gap * 1.5;

                @include breakpoint(medium) {
                    padding-top: $space-gap * 1.5;
                }
            }
            &-1 {
                padding-top: $space-gap;

                @include breakpoint(medium) {
                    padding-top: $space-gap;
                }
            }
            &-none {
                padding-top: 0;
            }
        }

        &.padding-bottom {
            &-6 {
                padding-bottom: $space-gap * 3.25;

                @include breakpoint(medium) {
                    padding-bottom: $space-gap * 7.5;
                }
            }
            &-5 {
                padding-bottom: $space-gap * 2.5;

                @include breakpoint(medium) {
                    padding-bottom: $space-gap * 5;
                }
            }
            &-4 {
                padding-bottom: $space-gap * 2;

                @include breakpoint(medium) {
                    padding-bottom: $space-gap * 4;
                }
            }
            &-3 {
                padding-bottom: $space-gap * 1.75;

                @include breakpoint(medium) {
                    padding-bottom: $space-gap * 2.5;
                }
            }
            &-2 {
                padding-bottom: $space-gap * 1.5;

                @include breakpoint(medium) {
                    padding-bottom: $space-gap * 1.5;
                }
            }
            &-1 {
                padding-bottom: $space-gap;

                @include breakpoint(medium) {
                    padding-bottom: $space-gap;
                }
            }
            &-none {
                padding-bottom: 0;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:56";