table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
        border: 1px solid;
        padding: 0.5rem;
        text-align: left;
        min-width: 8rem;
    }
    caption {
        margin-bottom: 1rem;
    }
    th {
        background: $color-beige-lightest;
    }
}
.block-table {
    overflow: auto;
}

;@import "sass-embedded-legacy-load-done:39";