@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';

.column:has(.carousel) {
    margin: 0 -1.5rem;
}

.glide {
    max-width: 320px;
    position: relative;
    padding-top: 1.5rem;
    margin: 0 auto;

    @media only screen and (min-width: 400px) {
        max-width: 400px;
    }

    @include breakpoint(medium) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        transform: none;
    }
    @include breakpoint(large) {
        padding-top: 0;
    }
    .button.glide__arrow--left,
    .button.glide__arrow--right {
        padding: 0.5rem;
        min-height: auto !important;
    }
    .glide__track {
        overflow: visible;
        overflow-x: clip;
        padding: 2rem 0;

        @include breakpoint(medium) {
            padding: 0;
        }
    }
    .glide__slide {
        width: 300px;
        max-width: 400px;
        opacity: 0.3;
        transition: all 0.2s ease;
        list-style: none;
        a {
            color: $color-black;
        }
        a:hover {
            text-decoration: none;
            h5,
            p {
                color: $color-black;
                text-decoration: none;
            }
        }
        &:before {
            content: '';
        }
        &.glide__slide--active {
            opacity: 1;
            transform: scale(110%);
            z-index: 1;

            img {
                height: 198px !important;
            }
        }
        img {
            height: 178px !important;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            object-fit: cover;
            width: 100%;
        }
    }
    .glide__slides {
        overflow: visible;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .glide__bullets {
        position: relative !important;
        bottom: auto;
        left: auto;
        transform: none;
        gap: 0.5rem;
    }
    .glide__bullet {
        width: 19px;
        height: 19px;
        box-shadow: none;
        margin-right: 0 !important;
        margin-left: 0 !important;
        overflow: hidden;
        border: none;
        width: 1rem;
        height: 1rem;
        background: $color-white;
        min-height: auto;
        transition: all 0.2s ease;
        border-radius: 50rem;

        // carousel progress bar
        .progress {
            appearance: none;
            display: flex;
            width: 48px;
            border-radius: 48px;
            height: 1rem;
            background-color: $color-white;
            overflow: hidden;
            opacity: 0;
            span {
                visibility: hidden;
            }
        }

        .bar {
            border-radius: 0px;
            width: 0;
            background-color: $color-black;
            overflow: hidden;
            transition: width 4.5s linear;
        }

        .progress.complete .bar {
            width: 100%;
        }

        .progress.paused {
            background: $color-black;
            .bar {
                opacity: 0;
            }
        }

        &--active {
            width: 48px;

            .progress {
                opacity: 1;
            }
        }
    }
    .glide__arrows {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin: 0;
        .icon {
            margin: 0;
        }
        button {
            border-style: none;
            padding: 1rem;
            z-index: 2;
            padding: 0.75rem;
            cursor: pointer;
            background-color: rgba($color-tertiary, 10%);
            margin: 0;
            left: unset;
            right: unset;
        }
    }
}

.carousel-content {
    padding: 1.5rem;
    background-color: $color-white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    max-height: 226px;
    p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    h5 {
        margin-bottom: 1rem;
    }
    .button {
        margin: 0;
        width: 100%;
        padding: 0.75rem 0;
        font-size: 0.875rem;
        min-height: auto;
    }
}

.carousel {
    &__controls {
        background-color: rgba($color-tertiary, 10%);
        padding: 12px 13px;
        border-radius: 48px;
        width: fit-content;
        display: flex;
        align-items: center;
        margin-top: 1.5rem;
        @include breakpoint(medium) {
            margin-top: 2.5rem;
        }
    }
    &-container {
        position: relative;
    }
    &__outer-container {
        display: flex;
        justify-content: space-between;
    }
    &__pause-btn,
    &__play-btn {
        margin: 0;
        padding: 0;
        min-height: auto;
        border: 0;
        background-color: transparent;
        .icon {
            width: 0.75rem;
            height: 0.75rem;
        }
    }
}

;@import "sass-embedded-legacy-load-done:44";