@import 'fields';

.formpage {
    .page-main {
        margin-top: 2.5rem;
    }
    #form-page-form {
        max-width: $width-text;
        padding: 0 1.5rem 3rem 1.5rem;
        margin-top: 0.5rem;
    }
    .section-block {
        p:empty {
            display: none;
            margin: 0;
        }

        .container {
            gap: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:25";