.static-quick-links {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: $color-white;
    display: flex;
    flex-flow: row nowrap;
    box-shadow: -2px 0px 12px -4px rgba($color-black, 0.5);

    .static-quick-link {
        display: flex;
        padding: 0.75rem 0.25rem 1rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        flex: 1 0 0;
        color: $color-tertiary;
        font-size: 0.6875rem;
        text-align: center;
        background: $color-white;
        transition: background-color 0.5s ease;

        &:hover {
            background: $color-lightest-grey;
            text-decoration: none;
            font-weight: 700;
        }
    }
}

;@import "sass-embedded-legacy-load-done:19";