.tile-block {
    .tile-grid {
        gap: 2rem;
    }
    a {
        color: $color-tertiary;

        &:hover {
            text-decoration: none;
        }
    }

    .tile {
        display: flex;
        gap: 1.5rem;

        * {
            margin: 0;
        }

        &--left {
            flex-flow: row wrap;
        }

        &--top {
            flex-flow: column wrap;
            align-items: center;
        }

        .tile__image {
            width: fit-content;
            height: fit-content;
            max-width: 7rem;
        }

        .tile__content {
            display: flex;
            flex-flow: column wrap;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 1;
            gap: 1rem;
        }
    }
}

.container--with-sidebar {
    .tile-grid--horizontal.grid--two {
        @include breakpoint(enormous) {
            .tile__content {
                width: 50%;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:42";