.home-page__banner {
    .header {
        width: 100%;
    }
}

.homepage {
    .grecaptcha-badge {
        display: none !important;
    }
}

;@import "sass-embedded-legacy-load-done:21";