.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &--wide {
        width: 100%;
        max-width: $width-block-wide;
        margin-left: auto;
        margin-right: auto;
    }

    &--with-sidebar {
        display: grid;
        grid-gap: $space-gap * 2;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(large) {
            grid-template-columns: $width-sidebar 1fr;
            grid-gap: $space-gap;
        }
        .standardpage & {
            max-width: 78.75rem;
        }
        .page-sidebar {
            padding: 1.5rem;
            transition: all 0.3s ease;

            @include breakpoint(large) {
                padding: 4rem 1.5rem;
            }

            .nav-tree {
                &__item {
                }
                &__link {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;
                    flex: 100%;
                    padding: 0.5rem;
                    transition: all 0.3s ease;
                    line-height: 1.2;

                    &:hover {
                        text-decoration: none;
                        background: $color-lightest-grey;
                    }

                    .icon {
                        fill: $color-tertiary;
                    }
                }
            }
        }
    }
    &--with-right-sidebar {
        display: grid;
        grid-gap: $space-gap * 2;
        width: 100%;
        max-width: $width-container;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(large) {
            grid-template-columns: 1fr $width-sidebar;
            grid-gap: $space-gap;
        }
    }
}

.main {
    position: relative;
    &.search {
        background: $color-light-grey;
        color: $color-black;
        .rich-text,
        .container {
            max-width: 79.5rem;
        }
    }
}

;@import "sass-embedded-legacy-load-done:15";