.image-block {
    text-align: center;
    position: relative;

    figure {
        display: inline-block;
    }
}

.image-block__credit {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    color: $color-primary-darker;
    a {
        color: $color-primary-darker;
    }
}

.image-block__image {
    a {
        display: block;
    }
}

.image-grid-block {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include breakpoint(small) {
        grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(medium) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    // columns: 3 18.5rem;
    // column-gap: 1rem;

    .image-block {
        height: 100%;
        margin: 0;
        width: 100%;

        figure {
            width: 100%;
            aspect-ratio: 3/2;

            a,
            .image-block__image {
                position: absolute !important;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.richtext-image {
    &.full-width {
        width: 100%;
    }

    &.left {
        float: left;
        max-width: 50%;
        margin: 0 1rem 1rem 0;
    }

    &.right {
        float: right;
        max-width: 50%;
        margin: 0 0 1rem 1rem;
    }
}

// Clearfix for floating rich text images
.rich-text::after {
    content: '';
    clear: both;
    display: table;
}

;@import "sass-embedded-legacy-load-done:48";